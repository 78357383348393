import React, {lazy, Suspense} from 'react';

import {WebsiteModuleFooter} from "./website-module.footer";
import {observer} from "mobx-react";
import {WebsiteModuleProps} from "../models/website-module.props";
import {WebsiteModuleController} from "../website-module.controller";
import {FloatingComponent} from "../../../core/components/floating-button/floating-component";
import {BolaoFloatingButton} from "../../../core/components/floating-button/bolao/bolao-floating-button";
import {ACTION_TYPES} from "../../../core/consts";
import styles from "../styles/website.module.scss";
import {SiteHeaderModuleAsync} from "../modules/site-header-module/components/site-header-module.async";
import classNames from "classnames";

const WebsiteModuleMobileBody = lazy(() => import('./website-module.mobile-main'));
const WebsiteModuleDesktopBody = lazy(() => import('./website-module.desktop-main'));

export class WebsiteFrameComponentProps extends WebsiteModuleProps {
  controller: WebsiteModuleController;
}

export const WebsiteModuleFrame = observer(({controller}: WebsiteFrameComponentProps) => {
  const {state, device} = controller;
  const {bolaoSettings, isAllScores} = state;
  const {isSmallView} = device;

  const floatingButtonProps = {
    isRtl: state.device.isRtl,
    ButtonComponent: BolaoFloatingButton,
    buttonComponentsProps: {url: bolaoSettings?.assets?.floating},
    onDrop: () => {
      controller.action(ACTION_TYPES.floatingButtonRemoveEvent);
      state.showFloatingButton = false;
    },
    onDisplay: () => controller.action(ACTION_TYPES.floatingButtonDisplayEvent),
    onClick: () => controller.action(ACTION_TYPES.floatingButtonClick, {url: bolaoSettings?.gameUrl})
  }

  return (
    <>
      {
        state.shouldRenderFloatingButton ? <FloatingComponent {...floatingButtonProps} /> : null
      }
      <div className={classNames( !isAllScores ? styles.header_container : styles.all_scores_header)}>
        <SiteHeaderModuleAsync isSmallView={isSmallView}/>
      </div>
      <Suspense fallback={<div className={classNames(styles.main_placeholder, {[styles.small_view]: isSmallView})}/>}>
        {
          isSmallView ? <WebsiteModuleMobileBody controller={controller}/> :
            <WebsiteModuleDesktopBody controller={controller}/>
        }
        <WebsiteModuleFooter controller={controller}/>
      </Suspense>
    </>
  );
});
