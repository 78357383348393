import {useEffect} from "react";

export const ExternalAdFallback = ({config}) => {
  const {width, height, adSenseId} = config;
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [adSenseId]);

  if(!adSenseId) {
    return null;
  }

  return  (
      <div>
        <ins className="adsbygoogle" style={{display: 'inline-block', width: width, height: height}}
             data-ad-client="ca-pub-8672827648650156"
            data-ad-slot={adSenseId}>
        </ins>
      </div>
  );
};
