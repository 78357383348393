import type {ModuleController} from "../controllers/module-controller";
import type {AdAdapexManager} from "../../core/managers/ad.adapex.manager";
import {useState} from "react";

const useAdAdapexManager = (controller: ModuleController, adUnit) => {
  const adManager: AdAdapexManager = controller.state.appState.adAdapexManager;
  const [isInitialized, setIsInitialized] = useState(adManager.isInitialized);

  adManager.registerToProviderReady(adUnit, (isReady) => {
    setIsInitialized(isReady);
  });

  return {
    initializeUnit: (units) => adManager.initializeUnit(units),
    refreshUnits: (units) => adManager.refreshUnits(units),
    refreshAll: () => adManager.refreshAll(),
    destroyUnits: (units) => adManager.destroyUnits(units),
    destroyAll: () => adManager.destroyAll(),
    isInitialized
  }
};

export default useAdAdapexManager;