import React from "react";

import {observer} from 'mobx-react';
import {runInAction} from 'mobx';
import {BaseProps} from "../../../../infrastructure/models/props/base-props";
import {WebsiteModuleSelectionToaster} from "../website-module.selection-toaster";
import {
  BottomNavigationBarModuleAsync
} from "../../modules/bottom-navigation-bar/components/bottom-navigation-bar-module.async";
import {WebsiteModuleStickyExternalAd} from "../conditional/website-module.sticky-external-ad";

export class WebsiteFrameBottomNavigationProps extends BaseProps {
}

export const WebsiteFrameBottomNavigation = observer(({controller}: WebsiteFrameBottomNavigationProps) => {
  const {state} = controller;
  const {shouldRenderBottomNavigationBar} = state;

  const onVisibilityChange = (visible) => {
    runInAction(() => {
      state.isBottomNavigationVisible = visible;
      state.isBottomNavigationForAdVisible = visible;
    })
  }

  return (
    <>
      {
        shouldRenderBottomNavigationBar ?
          <BottomNavigationBarModuleAsync
            onVisibilityChange={onVisibilityChange}/> : null
      }
      <WebsiteModuleSelectionToaster controller={controller}/>
      <WebsiteModuleStickyExternalAd controller={controller}/>
    </>
  )
});
