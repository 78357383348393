import classnames from "classnames";
import styles from './external-ad.module.scss';
import {useDisplayAdByLayout} from "../../../../infrastructure/hooks/useDisplayAdByLayout";
import {useEffect, useRef} from "react";
import {Clear} from "../../../icons/clear";
import useAdStatus from "../../../../infrastructure/hooks/useAdStatus";
import useAdAdapexManager from "../../../../infrastructure/hooks/useAdAdapexManager";
import {ExternalAdFallback} from "./external-ad.fallback";

export const ExternalAdContent = ({
                                    controller,
                                    config,
                                    className,
                                    isMock = false,
                                    shouldRenderClosingButton = false,
                                    shouldRenderTitle = true,
                                    onClose = null,
                                    FallbackComponent = null
                                  }) => {
  const currentAdUnitRef = useRef(null);
  const {isAdFilled} = useAdStatus(config?.adUnit);
  const isInitialized = useRef(false);
  const {initializeUnit, destroyUnits, isInitialized: isAdsInitialized} = useAdAdapexManager(controller);

  useEffect(() => {
    if (config?.adUnit && !isInitialized.current && (!currentAdUnitRef.current || currentAdUnitRef.current === config?.adUnit) && isAdsInitialized) {
      initializeUnit(config?.adUnit);
      isInitialized.current = true;
      currentAdUnitRef.current = config?.adUnit;
    }
  }, [isAdsInitialized, config?.adUnit]);

  useEffect(() => {
    return () => {
      destroyUnits([config?.adUnit]);
      currentAdUnitRef.current = null;
    }
  }, []);

  if (!config) {
    return null;
  }

  const {adUnit, width, height, validLayouts, hideWhenUnfilled} = config;

  const shouldDisplayAd = useDisplayAdByLayout(controller, validLayouts);

  if (!shouldDisplayAd) {
    return FallbackComponent ? FallbackComponent : null;
  }

  const handleCloseAd = () => {
    onClose?.();
  };

  return <div
      className={classnames({[styles.mock]: isMock, [styles.with_title]: shouldRenderTitle, [styles.hide]: hideWhenUnfilled && isAdFilled === false}, styles.container, className)}>
      {
        <div className={classnames(styles.text)}>
          Ad
        </div>
      }
      {
        isMock ?
          <div style={{minWidth: width, minHeight: height}} className={classnames(styles.ad)}/>
          :
            isAdFilled ? <div data-aaad="true" data-aa-adunit={adUnit} data-aa-lazy-loaded={true} className={classnames(styles.ad)}
               style={{minWidth: width, minHeight: height}}>
          </div> : isInitialized.current ? <ExternalAdFallback config={config}/> : null
      }
      {
        shouldRenderClosingButton && (<Clear className={styles.close_button} onClick={handleCloseAd}/>)
      }
    </div>
};
