import React, { useEffect, useState } from 'react';
import { ImageRequestParams } from "../../../infrastructure/models/params/image-request-params";
import { BaseImageProps } from "./base-image";
import { THEME } from "../../app/consts";
import { useFallbackRef } from "../../../infrastructure/hooks/useFallbackRef";
import { Image } from "./image";

export class SVGImageProps extends BaseImageProps {
  src: ImageRequestParams;
  eager: Boolean;
  theme: THEME;
  fillcolor: string;
  strokeColor: string;
  hoverFillColor: string;
  hoverStrokeColor: string;
}

export const SVGImage = React.forwardRef((props: SVGImageProps, forwardedRef) => {
  const ref = useFallbackRef(forwardedRef);
  const {fillcolor, strokecolor, hoverFillColor, hoverStrokeColor} = props;
  const [src, setSrc] = useState(null);

  // Handle hover color change
  const [hovered, setHovered] = useState(false);

  // Determine the final color to use for the SVG
  const finalFillColor = hoverFillColor && hovered ? hoverFillColor : fillcolor;
  const finalStrokeColor = hoverStrokeColor && hovered ? hoverStrokeColor : strokecolor;

  useEffect(() => {
    fetch(props.src)
        .then(response => response.text())
        .then(svgText => {
          // Create a temporary container to parse the SVG
          const div = document.createElement('div');
          div.innerHTML = svgText;

          // Find the <path> (or other elements you want to change) and set the fill color
          const paths = div.querySelectorAll('path');
          paths.forEach(path => {
            finalStrokeColor && path.setAttribute('stroke', strokecolor);
            finalFillColor && path.setAttribute('fill', fillcolor);

          });

          // Replace the original img source with the modified SVG as data URL
          setSrc('data:image/svg+xml,' + encodeURIComponent(div.innerHTML));
        });
  }, [props.src, finalStrokeColor, finalFillColor]);

  return (
      src ? <Image
          {...props}
          ref={ref}
          src={src}
          onMouseEnter={() => setHovered(true)}  // Set hover state on hover
          onMouseLeave={() => setHovered(false)} // Reset hover state when mouse leaves
      /> : null
  );
});