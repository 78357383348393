import {useState, useEffect, useCallback, useRef} from "react";

const useAdStatus = (path: string) => {
  const [isAdFilled, setIsAdFilled] = useState(true);
  const pathRef = useRef(path);

  useEffect(() => {
    pathRef.current = path;
  }, [path]);

  const checkAdStatus = useCallback(() => {
    if (window.googletag && window.googletag.pubads) {
      window.googletag.pubads()?.addEventListener("slotRenderEnded", (event) => {
        if (event.slot?.getAdUnitPath() === pathRef.current) {
          const adIsFilled = event.isEmpty === false;
          // Only trigger a re-render if the ad is EMPTY
          if (!adIsFilled && isAdFilled) {
            setIsAdFilled(false);
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (window.googletag && window.googletag.cmd) {
      window.googletag.cmd.push(checkAdStatus);
    }
  }, [checkAdStatus]);

  return {isAdFilled};
};

export default useAdStatus;