import React, {useEffect, useState} from 'react';

import {observer} from 'mobx-react';
import {WebsiteModuleProps} from "../../models/website-module.props";
import {ExternalAd} from "../../../../core/components/ad/external-ad/external-ad";
import {EXTERNAL_AD_CONFIG} from "../../consts/external-ad-config";
import classNames from "classnames";
import styles from "../../styles/website.module.scss";
import {AD_DISPLAYS} from "../../../../core/consts";

export const WebsiteModuleStickyExternalAd = observer(({controller}: WebsiteModuleProps) => {
  const {
    state: {
      isBottomNavigationForAdVisible,
      shouldDisplayStickyExternalAd
    }
  } = controller;

  const [isVisible, setIsVisible] = useState(shouldDisplayStickyExternalAd);

  const {state: {device: {isSmallView}}} = controller;

  useEffect(() => {
    setIsVisible(shouldDisplayStickyExternalAd);
  }, [shouldDisplayStickyExternalAd]);

  const externalAdProps = {
    controller,
    config: isSmallView ? EXTERNAL_AD_CONFIG.MOBILE.STICKY.AD320X100 : EXTERNAL_AD_CONFIG.DESKTOP.STICKY.AD970X90,
    isMock: controller.state.appState.settings.config.adDisplay === AD_DISPLAYS.placeholders,
    shouldRenderClosingButton: true,
    className: classNames(styles.sticky_ad),
    onClose: () => setIsVisible(false),
  }

  return isVisible ? <div className={classNames(styles.sticky_ad_container, {[styles.bottom_navigation_visible]: isBottomNavigationForAdVisible})}><ExternalAd {...externalAdProps} /></div> : null;
});
