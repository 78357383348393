import {ErrorBoundaryWrapper} from "../../error-boundary/error-boundary-wrapper";
import {ExternalAdContent} from "./external-ad.content";

export const ExternalAd = (props) => {
  if (!window.googletag) {
    return null;
  }

  return <ErrorBoundaryWrapper>
    <ExternalAdContent {...props}/>
  </ErrorBoundaryWrapper>
}

