import {LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS} from "../../core/consts";
import {SessionStorageUtils} from "../utils/session-storage.utils";
import {LocalStorageUtils} from "../utils/local-storage.utils";

export const useDisplayAdByLayout = (controller, adLayouts) => {
    let shouldDisplayAd = false;
    const localStorageUtils: LocalStorageUtils = controller.props.ioc.getInstance(LocalStorageUtils);
    const sessionStorage: SessionStorageUtils = controller.props.ioc.getInstance(SessionStorageUtils);
    const localeStorageCurrentSessionAdLayout = localStorageUtils.get(LOCAL_STORAGE_KEYS.externalAdLayoutDisplay);
    const sessionStorageCurrentSessionAdLayout = sessionStorage.get(SESSION_STORAGE_KEYS.externalAdLayoutDisplay);

    const currentSessionAdLayout = localeStorageCurrentSessionAdLayout > -1 ? localeStorageCurrentSessionAdLayout : sessionStorageCurrentSessionAdLayout;
    if(currentSessionAdLayout && adLayouts.includes(currentSessionAdLayout)){
      shouldDisplayAd = true;
    }

  return shouldDisplayAd;
};
